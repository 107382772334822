import React from 'react';
import PropTypes from 'prop-types';
import './Modal.css';
import Swal from 'sweetalert2'; // Asegúrate de que Swal esté instalado
import { EnviarCorreo } from '../../Service/GeneralService'; // Importa el método de envío de correo

const Modal = ({
  taller,
  onClose,
  email,
  setEmail,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  name,
  setName,
  phone,
  setPhone
}) => {
  const today = new Date().toISOString().split('T')[0];

  const handleSubmit = async () => {
    const emailContentSolicitante = `
      **Confirmación de Solicitud de Taller**

      Estimado/a ${name || '[Tu Nombre]'},

      Muchas gracias por tu interés en el taller "${taller.Tal_Nombre}".
      Has solicitado más información sobre este taller y tu intención de participar el día ${selectedDate} a las ${selectedTime} ha sido registrada.
      
      Nos pondremos en contacto contigo lo antes posible para proporcionarte los detalles adicionales y confirmar tu asistencia.

      Agradecemos tu interés y estamos a tu disposición para cualquier consulta adicional.

      Saludos cordiales,
      Equipo de Talleres
    `;

    const emailContentEquipo = `
      Solicitud de Taller

      Buen día,

      Se ha recibido una solicitud de información y registro para el taller "${taller.Tal_Nombre}".
      
      El solicitante, ${name || '[Tu Nombre]'}, ha mostrado interés en participar en el taller programado para el día ${selectedDate} a las ${selectedTime}.

      Información del solicitante:
      - Nombre: ${name || '[Tu Nombre]'}
      - Correo: ${email}
      - Teléfono: ${phone || '[Sin número de teléfono]'}

      Por favor, pónganse en contacto con el solicitante para proporcionarle más detalles sobre el taller y confirmar su registro.

      Saludos cordiales,
      Equipo de Talleres
    `;

    // Datos para enviar el correo al equipo (centrodeescriturasrb@una.cr)
    const dataEquipo = {
      to: 'centrodeescriturasrb@una.cr',  // Dirección de correo del equipo
      subject: `Solicitud de información del taller ${taller.Tal_Nombre}`,
      text: emailContentEquipo
    };

    // Datos para enviar el correo al solicitante
    const dataSolicitante = {
      to: email,  // Correo del solicitante
      subject: 'Confirmación de solicitud de taller',
      text: emailContentSolicitante
    };

    try {
      // Enviar correo al solicitante
      await EnviarCorreo(dataSolicitante);

      // Enviar correo al equipo
      await EnviarCorreo(dataEquipo);

      // Mostrar mensaje de éxito
      Swal.fire({
        title: 'Solicitud enviada',
        text: 'Tu solicitud ha sido enviada exitosamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });

      // Cerrar el modal después del envío
      onClose();
    } catch (error) {
      console.error("Error al enviar los correos:", error);

      // Mostrar mensaje de error
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al enviar tu solicitud, por favor intenta de nuevo.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-form">
          <h2>Solicitar Taller: {taller.Tal_Nombre}</h2>
          <p>{taller.Tal_Descripcion}</p>

          <label>Nombre :</label>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            className="modal-input"
            placeholder="Tu nombre"
          />

          <label>Teléfono (opcional):</label>
          <input
            type="tel"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            className="modal-input"
            placeholder="Tu teléfono"
          />

          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="modal-input"
          />

          <label>Fecha de interés:</label>
          <input
            type="date"
            value={selectedDate}
            onChange={e => setSelectedDate(e.target.value)}
            className="modal-input"
            min={today}
          />

          <label>Hora de interés:</label>
          <input
            type="time"
            value={selectedTime}
            onChange={e => setSelectedTime(e.target.value)}
            className="modal-input"
          />


        <div className="modal-preview">
          <h3>Previsualización del correo:</h3>
          <p>
            Estimado Centro de Escritura,<br />
            <br />
            Espero que este mensaje le encuentre bien. Mi nombre es {name || '[Tu Nombre]'}, y estoy interesado/a en el taller "<strong>{taller.Tal_Nombre}</strong>".<br />
            Me gustaría obtener más detalles sobre la fecha: {selectedDate} a las {selectedTime}.<br />
            <br />
            Agradezco su tiempo y atención.<br />
            <br />
            Atentamente,<br />
            {name || '[Tu Nombre]'}<br />
            {phone && `Teléfono: ${phone}`}<br />
            {email}
          </p>

          <div className="modal-buttons">
            <button onClick={handleSubmit}>Enviar</button>
            <button onClick={onClose}>Cancelar</button>
          </div>
        </div>

        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  taller: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  selectedTime: PropTypes.string.isRequired,
  setSelectedTime: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
};

export default Modal;
