import axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const guardarDatos = async (tabla, datos) => {
    try {
        const response = await axios.post('https://www.srb-unainvestigacion.org:3000/api/guardarDatos', { tabla, datos }, {
            headers: {
                Authorization: cookies.get('token')
            }
        }

        );
        return response.data;

    } catch (error) {
        console.error(error);
    }
}

export const EnviarCorreoDesdeSolicitante = async (datos) => {
    try {
        const response = await axios.post('https://www.srb-unainvestigacion.org:3000/api/sendMailFromUser', datos);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const editarDatos = async (prefijo, tabla, datos, id) => {
    try {
        const response = await axios.post('https://www.srb-unainvestigacion.org:3000/api/editarDatos', { prefijo, tabla, datos, id }, {
            headers: {
                Authorization: cookies.get('token')
            }
        });
        return response.data;

    } catch (error) {
        console.error(error);
    }
}

export const EnviarCorreo = async (datos) => {
    try {
        const response = await axios.post('https://www.srb-unainvestigacion.org:3000/api/sendMail', datos);
        return response.data;

    } catch (error) {
        console.error(error);
    }

}

export const CodigoRecuperacion = async (datos) => {
    try {
        const response = await axios.post('https://www.srb-unainvestigacion.org:3000/api/CodigoRecuperacion', datos);
        return response.data;

    } catch (error) {
        console.error(error);
    }
}

export const CambiarContrasena = async (datos) => {
    try {
        const response = await axios.put('https://www.srb-unainvestigacion.org:3000/api/user/password', datos);
        return response.data;

    } catch (error) {
        console.error(error);
    }
}

