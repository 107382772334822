import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './WorkshopStyle.css';
import Card from '../Card/Card';
import Modal from './Modal';

const Workshop = () => {
  const [talleres, setTalleres] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTaller, setSelectedTaller] = useState(null);
  const [email, setEmail] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const sections = [
    {
      title: "¿Cómo inscribirse en un taller?",
      content: "Selecciona el taller de tu interés, elige una fecha y hora disponibles y completa los campos de información personal. Recibirás un correo de confirmación con los detalles de tu inscripción.",
    },
    {
      title: "Tipos de talleres disponibles",
      content: "Nuestros talleres abarcan una amplia gama de temas, desde escritura creativa hasta habilidades de investigación y desarrollo profesional. Cada taller está diseñado para mejorar tus competencias y ofrecerte nuevas herramientas.",
    },
    {
      title: "Beneficios de participar en nuestros talleres",
      content: "Participar en nuestros talleres te permitirá desarrollar nuevas habilidades, conectar con otros participantes y acceder a material educativo exclusivo. Además, los talleres están dirigidos por expertos en cada tema.",
    },
    {
      title: "Preguntas frecuentes",
      content: "¿Cómo puedo cancelar mi inscripción? Puedes cancelar tu inscripción hasta 24 horas antes del taller sin penalización. ¿Qué pasa si no puedo asistir? Puedes reprogramar tu participación para una fecha futura si lo notificas con antelación.",
    },
    {
      title: "Contacto",
      content: "Si tienes alguna duda o necesitas asistencia, por favor contáctanos a centrodeescriturasrb@una.cr. Nuestro equipo de soporte estará encantado de ayudarte.",
    }
  ];

  useEffect(() => {
    axios.get('https://www.srb-unainvestigacion.org:3000/taller/GetTaller')
      .then(response => {
        setTalleres(response.data);
      })
      .catch(error => {
        console.error('Error obteniendo la lista de talleres:', error);
      });
  }, []);

  const handleOpenModal = (taller) => {
    setSelectedTaller(taller);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTaller(null);
    setEmail('');
    setSelectedDate('');
    setSelectedTime('');
    setName('');
    setPhone('');
  };

  const handleEnviarSolicitud = () => {
    if (!selectedTaller || !email || !selectedDate || !selectedTime) {
      alert("Por favor completa todos los campos requeridos antes de enviar el correo.");
      return;
    }
    const mensaje = encodeURIComponent(`
      Estimado Centro de Escritura,

      Espero que este mensaje le encuentre bien. Mi nombre es ${name || '[Tu Nombre]'}, y estoy interesado/a en el taller "${selectedTaller.Tal_Nombre}".

      Me gustaría obtener más detalles sobre la fecha: ${selectedDate} a las ${selectedTime}.

      Agradezco su tiempo y atención.

      Atentamente,
      ${name || '[Tu Nombre]'}
      ${phone && `Teléfono: ${phone}`}
      ${email}
    `);
    window.location.href = `mailto:centrodeescriturasrb@una.cr?subject=Solicitud de Información sobre el Taller&body=${mensaje}`;
  };

  return (
    <div className="workshop-container">
      <h1 className="workshop-title">Información General sobre los Talleres</h1>
      
      {/* Sección de información general */}
      <div className="info-sections">
        {sections.map((section, index) => (
          <div key={index} className="info-section">
            <h2 className="section-title">{section.title}</h2>
            <p className="section-content">{section.content}</p>
          </div>
        ))}
      </div>

      <h2 className="workshop-title">Lista de Talleres</h2>
      <div className="talleres-grid">
        {talleres.length > 0 ? (
          talleres.map((taller) => (
            <Card
              key={taller.Tal_Id}
              nombre={taller.Tal_Nombre}
              descripcion={taller.Tal_Descripcion}
              onClick={() => handleOpenModal(taller)}
            />
          ))
        ) : (
          <div className="loading">Cargando...</div>
        )}
      </div>

      {showModal && (
        <Modal 
          taller={selectedTaller}
          onClose={handleCloseModal}
          onSubmit={handleEnviarSolicitud}
          email={email}
          setEmail={setEmail}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          name={name}
          setName={setName}
          phone={phone}
          setPhone={setPhone}
        />
      )}
    </div>
  );
};

export default Workshop;
