import React from 'react';
import PropTypes from 'prop-types';
import './Card.css';

const Card = ({ nombre, descripcion, tutor, imagen, onClick }) => {
  return (
    <div className="taller-card" onClick={onClick} style={{ cursor: 'pointer' }}>
      <div className="taller-header">
        <h2 className="taller-nombre">{nombre || 'Nombre no disponible'}</h2>
      </div>


      <p className="taller-descripcion">{descripcion || 'Descripción no disponible'}</p>
      
      {tutor && (
        <p className="taller-tutor">
          <span className="tutor-label">Tutor:</span> {tutor}
        </p>
      )}
      
      <button className="solicitar-boton" onClick={(e) => { e.stopPropagation(); onClick(); }}>
        Solicitar
      </button>
    </div>
  );
};

Card.propTypes = {
  nombre: PropTypes.string,
  descripcion: PropTypes.string,
  tutor: PropTypes.string,
  imagen: PropTypes.string, 
  onClick: PropTypes.func.isRequired,
};

Card.defaultProps = {
  nombre: 'Taller sin nombre',
  descripcion: 'Descripción no disponible',
  tutor: null,
  imagen: null,
};

export default Card;
